/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card04 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Chief of Mission at IOM - UN Migration."
        image={
          <StaticImage
            alt="Abdusattor Esoev, MBA, PhD"
            className="w-16 h-16"
            src="./user.png"
          />
        }
        isVisible={isVisible}
        name="Abdusattor Esoev, MBA, PhD"
        text="I know Mr. Raja as a dedicated and loyal colleague who I had privilege to work with during my work for IOM Afghanistan. Being based in the sub-office I was in constant contact with Raja who was based in Kabul to jointly work out strategy on programme operations in the field. I find Raja a reliable and highly professional colleague who stands ready to provide expert support in his area of responsibilities, mainly on emergency operation in humanitarian field.

        I am confident that Mr. Raja could be an asset to the position he is applying and that IOM DRC will benefit a lot from his professional experience in the field of emergency and post crises programme

        Sincerely, Abdusattor."
      />
    )}
  </VisibilitySensor>
);
