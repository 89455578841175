/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card07 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Chief Architect / Engineering Manager at Sallyport Global Services."
        image={
          <StaticImage alt="Joe N." className="w-16 h-16" src="./card-07.jpg" />
        }
        isVisible={isVisible}
        name="Joe N."
        text="I worked with Raja Kuppuswamy for approximately a year and half as part of the CHEF/EDID vertical construction program at IOM. Raja was a key member of the organization who oversaw the Construction Management team and contributed significantly to the successful completion and close-out of our projects. He has an outstanding work-ethic, works well in a team environment and was a pleasure to work with."
      />
    )}
  </VisibilitySensor>
);
