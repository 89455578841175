/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card19 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Senior Donor Manager at Gavi, the Vaccine Alliance."
        image={
          <StaticImage
            alt="Yoshinobu Nagamine"
            className="w-16 h-16"
            src="./card-19.jpg"
          />
        }
        isVisible={isVisible}
        name="Yoshinobu Nagamine"
        text="I have known Raja as a lucent and very sociable colleague. During my field research I was impressed to see his drive for results and quality of projects as well as the trust he enjoyed from his superior and his local partners. If I had a chance to choose my work colleague he would be definitely on the top of the list. I sincerely wish him good luck and all the best in his future endeavors."
      />
    )}
  </VisibilitySensor>
);
