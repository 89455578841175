/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card20 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Technical Advisor at EPCM Meinhardt Group."
        image={
          <StaticImage
            alt="A. Rizaldi Lubis"
            className="w-16 h-16"
            src="./card-20.jpg"
          />
        }
        isVisible={isVisible}
        name="A. Rizaldi Lubis"
        text="Raja is the effective manager who could trained and established simple anagement on design delivery for more than 6.0000 houses, some school building and community building on 6 months scattered in 15 districts in Aceh.
        He has skill to develop limited skill on local engineer become more productive and successfully to achieve team objective and finally influence entire construction project objective.
        He could lead the team design effectively and give valuable support to resolve any problem in smart way.
        He also expert to establish smooth coordination and communication to other manager especially for each construction project manager who ordered design to team.
        He is a wise person also who understand the local capacity and has high attention to develop and willing his skill and experiences to the team.
        He is expert to develop tools and implementation technique on QA/QC for construction."
      />
    )}
  </VisibilitySensor>
);
