/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card15 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Technical Director at AECOM."
        image={
          <StaticImage
            alt="Kanna Dasan Narayanasamy, P.E., M.ASCE"
            className="w-16 h-16"
            src="./card-15.jpg"
          />
        }
        isVisible={isVisible}
        name="Kanna Dasan Narayanasamy, P.E., M.ASCE"
        text="I've worked with Raja for a number of years at Denver International Airport, KL International Airport and KL Monorail. At KL Monorail he was the Project Manager for the Infrastructure Bank. Raja is always professional, thorough and conscientious. He has exhibited his broad ranging project management skills and knowledge."
      />
    )}
  </VisibilitySensor>
);
