/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card23 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="FAACE Hon. Life."
        image={
          <StaticImage
            alt="Stephen Warhoe, PhD, PE, CCP, CFCC"
            className="w-16 h-16"
            src="./card-23.jpg"
          />
        }
        isVisible={isVisible}
        name="Stephen Warhoe, PhD, PE, CCP, CFCC"
        text="After working with Raja on the construction of the Kuala Lumpur Interational Airport, I found that he was a man who is passionate about construction management and being a great team mate. Raja and I worked together on the team representing the Malaysian government as construction management consultants and the developer of the program's master schedule. Raja was a great help to me and others of our team. He showed a good knowledge of CPM scheduling and construction practices. I would recommend Raja as a team mate on any construction management team."
      />
    )}
  </VisibilitySensor>
);
