/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card06 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Operations Officer at International Organization for Migration."
        image={
          <StaticImage
            alt="Eli Abesamis"
            className="w-16 h-16"
            src="./card-06.jpg"
          />
        }
        isVisible={isVisible}
        name="Eli Abesamis"
        text="Raja is a very professional person. He takes his works seriously and diligently. He had extensive knowledge in construction quality control and had develop the Quality Control Manual and Technical Specification for all IOM CHEF-EDID projects.

        I am a Const Manager who directly reported to Raja. I found him a very understanding and warm person. He patiently teach and trained me and other CMs and QCs in the field of Quality Control and Project Management. He constantly monitor and support us in resolving quality and management problems in construction site towards a common goal of completing project to the specified quality and standard."
      />
    )}
  </VisibilitySensor>
);
