/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card01 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Project Coordinator, Head of the DTM (Displacement Tracking & Monitoring) Unit, at The UN Migration Agency (IOM), Maiduguri - Nigeria."
        image={
          <StaticImage
            alt="Denis Wani"
            className="w-16 h-16"
            src="./card-01.jpg"
          />
        }
        isVisible={isVisible}
        name="Denis Wani"
        text="Mr. Raja Segaran Kuppusamy is an experienced engineer that served with IOM for numerous years. I have to have known him as well as work closely with him while working for IOM Iraq Mission. He was among the first to set up the emergency operation response in Iraq at the time of Mosul preparedness. He was able to plan, monitor and supervise the setting up of camps in Iraq as well as monitoring the implementation of critical shelter and shelter upgrade facilities in Iraq. He is skilful, professional, committed, a team player, a good planner, and humble. He is definitely a person that many would want to work with. I highly recommend him for any job that is related to his expertise."
      />
    )}
  </VisibilitySensor>
);
