/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card13 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Engineer at KAR consultants Sdn Bhd."
        image={
          <StaticImage
            alt="K.Rukmangathan Kalian"
            className="w-16 h-16"
            src="./user.png"
          />
        }
        isVisible={isVisible}
        name="K.Rukmangathan Kalian"
        text="Mr.Raja Kuppusamy is an excellent staff at Greiner where he was engaged in project planning and interface scheduling for Kuala Lumpur International Airport construction in 90swhere i was Asst Gen Mgr .He is damn good in his work and was well liked by the client.For any of his future employers, he is an valuable asset for the organization. My rating 10/10."
      />
    )}
  </VisibilitySensor>
);
