/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card18 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Principal IT Solutions Delivery."
        image={
          <StaticImage
            alt="Narendra Poflee"
            className="w-16 h-16"
            src="./card-18.jpg"
          />
        }
        isVisible={isVisible}
        name="Narendra Poflee"
        text="Raja and I studied at Colorad State University in early 1990's.
        After finishing his graduation he worked in the construction of the new Denver International Airport at Colorad, USA.
        He is very hard-working, diligent, focused, level-headed, and resourceful.
        On a personal level he is very trustworthy and reliable and very fai.
        He will be a great asset to your team and the best person manage a team."
      />
    )}
  </VisibilitySensor>
);
