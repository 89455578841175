import React from 'react';
import xw from 'twin.macro';
import * as Cards from './cards';

const styles = {
  heading: xw`text-3xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 mb-2 text-center`,
};

function Component() {
  return (
    <div className="relative max-w-lg px-4 pt-6 pb-20 mx-auto sm:px-6 lg:pt-12 lg:pb-28 lg:px-8 lg:max-w-7xl">
      <h1 className="text--color-heading-1" css={[styles.heading]}>
        Testimonials
      </h1>

      <div className="grid gap-16 pt-12 lg:grid-cols-2">
        <Cards.Card01 />
        <Cards.Card02 />
        <Cards.Card03 />
        <Cards.Card04 />
        <Cards.Card05 />
        <Cards.Card06 />
        <Cards.Card07 />
        <Cards.Card08 />
        <Cards.Card09 />
        <Cards.Card10 />
        <Cards.Card11 />
        <Cards.Card12 />
        <Cards.Card13 />
        <Cards.Card14 />
        <Cards.Card15 />
        <Cards.Card16 />
        <Cards.Card17 />
        <Cards.Card18 />
        <Cards.Card19 />
        <Cards.Card20 />
        <Cards.Card21 />
        <Cards.Card22 />
        <Cards.Card23 />
        <Cards.Card24 />
      </div>
    </div>
  );
}

export const Main = Component;
