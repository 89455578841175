/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card09 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Project Management Practitioner."
        image={
          <StaticImage
            alt="Bambang Triyanto"
            className="w-16 h-16"
            src="./user.png"
          />
        }
        isVisible={isVisible}
        name="Bambang Triyanto"
        text="Excellent Construction Manager, good leadership, a professional in his field."
      />
    )}
  </VisibilitySensor>
);
