/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card14 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Experienced Software Architect."
        image={
          <StaticImage
            alt="Naresh Janarthanam"
            className="w-16 h-16"
            src="./card-14.jpg"
          />
        }
        isVisible={isVisible}
        name="Naresh Janarthanam"
        text="Raja is a wonderful and very hard working person. He was my roommate. I was very impressed how he was working full time and also finishing up his MS degree at Colorado State University. He is a very good leader, arbitrator and well organized person. I would highly recommend Raja for any challenging projects that require leadership and good management skills."
      />
    )}
  </VisibilitySensor>
);
