/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card03 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Project Final Evaluator at Le Groupe-conseil baastel."
        image={
          <StaticImage
            alt="Devi Sa'adah"
            className="w-16 h-16"
            src="./card-03.jpg"
          />
        }
        isVisible={isVisible}
        name="Devi Sa'adah"
        text="Raja's leadership is a confident leader,executor and communicator with strong interpersonal and analytical skills who thrives on social interaction and customer/donor satisfaction."
      />
    )}
  </VisibilitySensor>
);
