/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import xw from 'twin.macro';

interface Props {
  bio: string;
  image: JSX.Element;
  isVisible: boolean;
  name: string;
  text: string;
}

const styles = {
  hide: xw`translate-y-8 opacity-0`,
  show: xw`opacity-100`,
};

export const BaseCard = (props: Props) => {
  const { bio, image, isVisible, name, text } = props;

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsShow(true);
    }
  }, [isVisible]);

  return (
    <div
      className="p-4 text-gray-800 transition duration-500 ease-in-out bg-gray-100 rounded-lg filter drop-shadow-lg dark:bg-gray-800 sm:px-10 sm:pb-12 dark:text-white"
      css={isShow ? [styles.show] : [styles.hide]}
    >
      <blockquote className="flex flex-col mt-8">
        <div className="relative flex-grow text-lg font-medium leading-7">
          <svg
            className="absolute top-0 left-0 w-8 h-8 transform -translate-x-6 -translate-y-8 text-brand-blue dark:text-indigo-600"
            fill="currentColor"
            viewBox="0 0 32 32"
          >
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p className="relative">{text}</p>
        </div>
        <footer className="mt-8">
          <div className="sm:flex">
            <div className="w-16">
              <div className="w-16 h-16 overflow-hidden rounded-full">
                {image}
              </div>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-6">
              <div className="text-base font-bold leading-6 text-black dark:text-white">
                {name}
              </div>
              <div className="text-sm font-medium text-gray-800 dark:text-indigo-200">
                {bio}
              </div>
            </div>
          </div>
        </footer>
      </blockquote>
    </div>
  );
};
