/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card22 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Co-Founder / COO at AustAsia Project Services."
        image={
          <StaticImage
            alt="John Arman Serenuela, PMP,IAM-C"
            className="w-16 h-16"
            src="./card-22.jpg"
          />
        }
        isVisible={isVisible}
        name="John Arman Serenuela, PMP,IAM-C"
        text="I've known Raja when I joined International Organization for Migration-Kabul last 2010. He was heading the overall CHEF program under USAID and I was reporting to him as one of the International Construction Manager consultants. I find Raja to be a very objective manager, he was very clear on setting up goals for the managers to ensure successful program execution. His knowledge on construction quality control and quality assurance had been instrumental in the formulation and execution of the program's quality plan which was the vital aspect in doing projects in Afghanistan. He's very keen on every minute details relating to safety on the site. He also got good network of peers with many other agencies which showed the good relationship built over time. I recommend Raja for any post relating this title."
      />
    )}
  </VisibilitySensor>
);
