/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card11 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Partner, Oharco."
        image={
          <StaticImage
            alt="Mike Overfelt"
            className="w-16 h-16"
            src="./card-11.jpg"
          />
        }
        isVisible={isVisible}
        name="Mike Overfelt"
        text="This is one of the smartest people I have ever had the privilage of knowing. You cant go wrong with Raja."
      />
    )}
  </VisibilitySensor>
);
