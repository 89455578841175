/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card17 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="HSE-Q Executive at PT.Tirta Samudera Caraka."
        image={
          <StaticImage
            alt="Yanti Yuliastri"
            className="w-16 h-16"
            src="./card-17.jpg"
          />
        }
        isVisible={isVisible}
        name="Yanti Yuliastri"
        text="Raja is highly intelligent and diligent manager. He always motivates his staff to work efficient as possible. Raja also always focus with his workload and very initiative personal. Raja also is a great team work. he always encourage us to work as team work as it is very to achieve optimal results. I highly recommend Raja as Engineering & Construction QA/QC & HSE Manager at Inter Governmental Organization (International)."
      />
    )}
  </VisibilitySensor>
);
