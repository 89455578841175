/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card02 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Associate Director Business Development at KOLLECT SYSTEMS SDN. BHD."
        image={
          <StaticImage
            alt="Biman Bardhan"
            className="w-16 h-16"
            src="./card-02.jpg"
          />
        }
        isVisible={isVisible}
        name="Biman Bardhan"
        text="I have known Raja for a good many years and have kept in touch thru his colorful career history since his days in engineering consultancy in Kuala Lumpur including his time as key lead consultant to the world class Kuala Lumpur International Airport and Kuala Lumpur Monorail projects.

        Raja is never shy to a challenge and has a passion & drive to contribute to excellence in his undertakings, constantly challenging himself and the norm.

        He has a willingness & stamina to work in unforgiving and unstable regions rebuilding infrastructure post Aceh tsunami, Afghanistan and now Iraq with his current employer. He is indeed a toughened veteran providing much needed expertise and project management skills to highly challenging work environments.

        He is an asset to any organization and a very ethical person."
      />
    )}
  </VisibilitySensor>
);
