/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card10 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Managing Director of Next Level Technologies Sdn Bhd."
        image={
          <StaticImage
            alt="Azman Hamid"
            className="w-16 h-16"
            src="./card-10.jpg"
          />
        }
        isVisible={isVisible}
        name="Azman Hamid"
        text="Raja has been very helpful in assisting us to get information on Afghanistan where he works. We have become close friends ever since."
      />
    )}
  </VisibilitySensor>
);
