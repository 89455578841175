/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card08 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Commercial Manager at Transport for London."
        image={
          <StaticImage
            alt="Martin Lyria Liria, MRICS"
            className="w-16 h-16"
            src="./card-08.jpg"
          />
        }
        isVisible={isVisible}
        name="Martin Lyria Liria, MRICS"
        text="Raja has a wealth of experience in Quality Control of Construction and Engineering works which he loved sharing with the several engineers who worked under him in Afghanistan. He would be a great asset to any organization involved in Building and Civil Engineering Works."
      />
    )}
  </VisibilitySensor>
);
