/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card16 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="QA/QC Manager at IJM Construction."
        image={
          <StaticImage
            alt="Ravindran Kuttan"
            className="w-16 h-16"
            src="./card-16.jpg"
          />
        }
        isVisible={isVisible}
        name="Ravindran Kuttan"
        text="I have worked with Raja and have found him to be very knowlegeble in his field. Over the years it has being fun working with him, as he has showed me different approches to some of the problems encounted. I wish him the best in his future undertakings and I hope we could work together again in future."
      />
    )}
  </VisibilitySensor>
);
