/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card24 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Engineer Project Manager at World Food Programme."
        image={
          <StaticImage
            alt="Yamanta Raj Niroula, PMP"
            className="w-16 h-16"
            src="./user.png"
          />
        }
        isVisible={isVisible}
        name="Yamanta Raj Niroula, PMP"
        text="Mr. Raja has been my supervisor in IOM CHEF Program for more than one year. He is an enthusiastic and dedicated Senior Manager with stupendous working habits. Very goal oriented as well as a team player, he is efficient in every aspects of management viz. leadership, coaching/mentoring, vision and teamwork. He has been always caring and supportive, both professionally and personally.

        His background, professional experience and work style has equipped him to offer a rare overview of the field along with a profound grasp of management knowledge. This mix is quite unusual among professionals in engineering/project management field. He can always contribute effectively within a variety of engineering environments.

        I admire Mr. Raja, more and more everyday that goes by, for his valuable qualities and regard him with evermore respect."
      />
    )}
  </VisibilitySensor>
);
