/* eslint-disable max-len */
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { BaseCard as Card } from './baseCard';

export const Card21 = () => (
  <VisibilitySensor delayedCall partialVisibility offset={{ top: 95 }}>
    {({ isVisible }) => (
      <Card
        bio="Head of Operation at Orient Telecoms Sdn Bhd."
        image={
          <StaticImage
            alt="Eswaran Pramasamy"
            className="w-16 h-16"
            src="./card-21.jpg"
          />
        }
        isVisible={isVisible}
        name="Eswaran Pramasamy"
        text="Raja is highly knowledgeable & specialized in Project Management role.Raja has completed projects with overall cost reduction & within time schedule and rewarded for high engineering standards, quality, HSE & management with solving complex technical, commercial, contractual, governmental & regulatory issues with people management."
      />
    )}
  </VisibilitySensor>
);
